import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  data() {
    return {
      requestType: 'POST',
    };
  },
  mounted() {
    this.getConfigList('page_list');
  },
  methods: {
    // 弹框操作
    modalClick({ val, row }) {
      if (val.code === 'detail') {
        this.propsObjInData.id = row.id;
        this.modalConfig.title = '明细';
        this.openFull();
      }
    },
  },
};
